import { CropCalendarDTO } from './cropCalendar.dto'

export class CropCalendarData {
  filterZones: Array<string> = [];

  constructor(private data: Array<CropCalendarDTO> = []) {
    this.fixData();
  }

  setData(data: Array<CropCalendarDTO>) {
    this.data = data || [];
    this.fixData();
  }

  setFilterZones(selectedZones: string[]) {
    this.filterZones = selectedZones;
  }

  getData() {
    if (this.filterZones.length > 0) {
      return this.getFilteredData(this.filterZones);
    }

    return this.data;
  }

  hasData() {
    return this.data.length > 0;
  }

  getLastUpdated() {
    return this.data[0].lastUpdated;
  }

  getZones(): Array<{id: string, name: string}> {
    let zones = [];

    for (let data of this.data) {
      if (!zones.some(item => item.id === data.aez.id)) {
        zones.push(data.aez);
      }
    }

    return zones.sort((a, b) => (a.name || "").localeCompare((b.name || "")));
  }

  getDataGroupByZone() {
    let zones = {};
    let dataZones = [];

    this.getData().forEach((item) => {
      const id = item.aez.id;
      let index = dataZones.length;
      if (!zones.hasOwnProperty(id)) {
        const aez = {
          aez: item.aez,
          chartBars: [],
          dataSource: [],
        };
        dataZones.push(aez);
        zones[id] = index;
      } else {
        index = zones[id];
      }
      if (this.checkSession(item)) {
        dataZones[index].dataSource.push(item);
      }
     
    });

    dataZones = dataZones.sort((a, b) => (a.name || "").localeCompare((b.name || "")));

    for (let index = 0; index < dataZones.length; index++) {
      dataZones[index].dataSource = dataZones[index].dataSource
        .filter((e) => ((e.crop.name || "").length))
        .sort((a, b) => a.crop.name.localeCompare(b.crop.name));
    }

    return dataZones.filter (a=>a.dataSource.length>0);
  }

  private fixData() {
    this.data = this.data.map(data => {
      if (!data.aez.id) {
        data.aez = { id: '0', name: '---' };
      }
      return data;
    });
  }

  private checkSession(item) {
      return item && item.sessions && item.sessions.length && item.sessions.length>0;
  }

  private getFilteredData(selectedZones: string[]) {
    const data = this.data.filter(
      item => selectedZones.includes(item.aez.id)
    );

    return data;
  }

}
