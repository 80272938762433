import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NoPageFoundComponent } from './shared/components/no-page-found/no-page-found.component';


const routes: Routes = [
 { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent },
  { path: 'home/:id/:crops', component: HomeComponent },
 // { path: 'admin-temporary', component: HomeComponent },
 // { path: '**', component: NoPageFoundComponent },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Tell the router to use the HashLocationStrategy.
      useHash: true,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
   })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
