import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multilanguage',
  templateUrl: './multilanguage.component.html',
  styleUrls: ['./multilanguage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultilanguageComponent {
  lang = 'en';


  constructor(public translate: TranslateService) {
  }

  ngOnInit() {}

  switchLang(lang) {
    this.translate.use(lang);

    if (lang !== 'ar') {
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      document.querySelector('html').setAttribute('dir', 'ltr');

    } else if (lang === 'ar' && document.getElementsByTagName('html')[0].hasAttribute('dir')) {
      document.querySelector('html').setAttribute('lang', 'ar');
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }

    localStorage.setItem(this.lang, lang);
  }
}
