import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-search',
  templateUrl: './other-search.component.html',
  styleUrls: ['./other-search.component.scss']
})
export class OtherSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

