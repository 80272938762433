
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { CropCalendarModule } from './crop-calendar/crop-calendar.module';
import { HomeComponent } from './components/home/home.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { HelpCropComponent } from './components/help-crop/help-crop.component';
import { OtherSearchComponent } from './components/other-search/other-search.component';
import { MatOptionModule } from '@angular/material';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { CropCalendarService } from './crop-calendar/services/api/cropcalendar-api.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FilterService } from './crop-calendar/services/api/filter.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ModalInfoComponent,
    HelpCropComponent,
    OtherSearchComponent


  ],
  imports: [
    CropCalendarModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    LazyLoadImageModule,
  ],
  providers: [CropCalendarService, FilterService],
  bootstrap: [AppComponent],
  entryComponents: [ModalInfoComponent],
})
export class AppModule { }




