import { Injectable } from "@angular/core";
import { CropDTO } from '../../models/crop.dto';
import { BehaviorSubject } from 'rxjs';
import { Filters } from '../../models/filters.dto';


@Injectable({
  providedIn: "root"
})
export class FilterService {

    countryId: Filters;
    crops: string[];
    country: string;
    zones: string[];
    cropsList: CropDTO[];

    date$ = new BehaviorSubject<string>("");

    constructor() {
    }

    getCountry() {
        return this.countryId;
      }

    setCountry(countryId: Filters) {
        this.countryId = countryId;
      }

    getListCrops() {
        return this.crops;
    }

    setListCrops(crops: string[]){
        this.crops = crops;

    }

    getCropListImages(){
        return this.cropsList;
    }

    setCropListImages(cropsList: CropDTO[]) {
        this.cropsList = cropsList;
    }

    getListCountries() {
        return this.country;

    }

    setListCountries(country: string){
        this.country = country;
    }

    getListZones() {
        return this.zones;

    }

    setListZones(zones: string[]){
        this.zones = zones;
    }

    setLastUpdate(lastUpdated: string) {
        this.date$.next(lastUpdated);
    }
}
