import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Filters } from "src/app/crop-calendar/models/filters.dto";
import { definitions } from "src/app/crop-calendar/models/swagger-schema";
// import { filter } from 'minimatch';
import { CropCalendarService } from "src/app/crop-calendar/services/api/cropcalendar-api.service";
import { environment } from 'src/environments/environment';
import {UtilsService} from 'src/app/crop-calendar/services/utils.service';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  loader: boolean;
  searchLoader = false;
  dataFilter: Filters;

  pageFound = true;
  showNext = false;
  hiddenInfo = false;
  hiddenHelp = false;
  names;
  allCountries: any;
  routefilter: { id: any[]; crops: any[]; name: any[] };
  countryNames: any;
  cropCalendars: definitions["CropCalendarsResponse"] = [];

  environment = environment;

  constructor(
    private route: ActivatedRoute,
    private cropcalendarService: CropCalendarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loader = true;
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length !== 0 && params.constructor === Object) {

        this.routefilter = {
          id: [],
          crops: [],
          name: [],
        };
        if (params["id"]) this.routefilter.id = params["id"].split(",");
        if (params["crops"])
          this.routefilter.crops = params["crops"].split(",");

        // if the url was changed manually to force the limit
        if (!(
          (!this.environment.maxSelectedCountries ||
            this.routefilter.id.length < this.environment.maxSelectedCountries + 1) &&
          (!this.environment.maxSelectedCountriesNoCrops ||
            this.routefilter.crops.length > 0 ||
            this.routefilter.id.length < this.environment.maxSelectedCountriesNoCrops + 1)
        )) {
          this.router.navigate(["home"]);
          return;
        }

        forkJoin({
          countries: this.getCountries(),
          cropCalendars: this.getAllCropCalendars(this.routefilter.id, this.routefilter.crops),
        }).subscribe((data) => {
          this.routefilter.id = (this.routefilter.id.length)?
            this.routefilter.id:
            UtilsService.getCountriesFormCropCalendar(data.cropCalendars);
          this.countryNames = data.countries.filter((e)=>~this.routefilter.id.indexOf(e.id));

          this.routefilter.name = this.countryNames;

          this.showAEZinfo(this.dataFilter || this.routefilter);
          this.loader = false;
        });
      } else {
        this.routefilter = null;
        this.loader = false;
      }
    });
  }
  /**
   * Get All Crop Calendars.
   *
   * @param countries array with the countries ids
   * @param crops array with the crops ids
   * @returns a promise
   */
  getAllCropCalendars(countries: string[], crops: string[]): Observable<definitions['CropCalendarsResponse']> {
    this.searchLoader = true;
    this.cropCalendars = [];

    const promise = this.cropcalendarService.getAllCropCalendars(countries, crops).pipe(map((data) => {
      this.cropCalendars = data;
      this.searchLoader = false;
      return data;
    }));
    return promise;
  }

  /**
   * Filter the Crop Calendars By Country.
   *
   * @param country the country id to filter
   * @returns the cropCalendars filtered
   */
  filterCropCalendarsByCountry(country: string): definitions['CropCalendarsResponse'] {
    return this.cropCalendars.filter((e) => e.id_country === country);
  }

  getCountries(): Observable<[definitions['Country']]> {
    const promise = this.cropcalendarService.getAllCountries().pipe(map((data) => {
      this.allCountries = data;
      return data;
    }));
    return promise as Observable<[definitions['Country']]>;
  }

  showAEZinfo(filter) {
    this.dataFilter = filter;
    if (!this.dataFilter.id.length && !this.dataFilter.crops.length) {
      this.hiddenInfo = false;
      this.showNext = false;
    } else {
      this.hiddenInfo = true;
      this.showNext = true;
    }
    this.hiddenHelp = false;
  }

  showHelp(filter) {
    this.dataFilter = filter;
    // this.dataFilter.crops = [];
    this.hiddenInfo = true;
    this.showNext = false;
    this.hiddenHelp = true;
  }
}
