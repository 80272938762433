import { Injectable } from "@angular/core";
import { definitions } from "../models/swagger-schema";

@Injectable({
  providedIn: "root",
})
/**
 * Utility Service.
 */
export class UtilsService {
  /**
   * Get the list of Countries Form CropCalendar data.
   *
   * @param data the data from the service.
   * @returns a list of countries id. [] otherwise.
   */
  public static getCountriesFormCropCalendar(
    data: definitions["CropCalendarsResponse"],
  ): string[] {
    if (!data) {
      return [];
    }

    return [...new Set(data.map((e) => e.id_country))];
  }
}
