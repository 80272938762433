// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api-cropcalendar.review.fao.org/api/v1/',
  // apiBaseUrl: 'http://localhost:8080/api/v1/',

  // Max Selected Countries: set this to 0 to disable the limit
  maxSelectedCountries: 0,
  // Max Selected Countries without any crop selected: set this to 0 to disable the limit
  maxSelectedCountriesNoCrops: 3,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
