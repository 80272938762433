import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatSelectModule } from '@angular/material';

import { HeaderComponent } from './components/header/header.component';
import { MultilanguageComponent } from './components/multilanguage/multilanguage.component';
import { NoPageFoundComponent } from './components/no-page-found/no-page-found.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatOptionModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        LazyLoadImageModule,
    ],
    declarations: [
        HeaderComponent,
        MultilanguageComponent,
        NoPageFoundComponent,
        SelectAutocompleteComponent,
    ],
    exports: [
        HeaderComponent,
        TranslateModule,
        NoPageFoundComponent,
        SelectAutocompleteComponent,
        LazyLoadImageModule,
    ],
    providers: [

    ]
})
export class SharedModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
