import { DOCUMENT } from "@angular/common";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { CropCalendarDTO } from "../../models/cropCalendar.dto";
import { CropCalendarData } from "../../models/cropCalendarData";
import { Filters } from "../../models/filters.dto";
import { definitions } from "../../models/swagger-schema";
import { CropCalendarService } from "../../services/api/cropcalendar-api.service";
import { FilterService } from "../../services/api/filter.service";
import { ModalAllZonesComponent } from "./modal-all-zones/modal-all-zones.component";
import { ModalInfoCropComponent } from "./modal-info-crop/modal-info-crop.component";

@Component({
  selector: "app-crop-calendar-country",
  templateUrl: "./crop-calendar-country.component.html",
  styleUrls: ["./crop-calendar-country.component.scss"],
})
export class CropCalendarCountryComponent
  implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild("table", { read: ElementRef, static: false }) table: ElementRef;
  @Input() userDataFilter: Filters;
  @Input() country: string;
  @Input() countryIndex: number;
  public loader: boolean = true;
  @Input() cropCalendars: definitions["CropCalendarsResponse"] = [];

  @HostListener("window:resize", []) public onResize() {
    this.windowResize$.next(true);
  }

  lastUpdated: any;
  dataZones = [];
  displayedColumns = [
    "name",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
  ];
  firstViewChecked = true;
  heigthBar = 42;
  heightFirstRow = 42;
  heightRow = 84;
  widthFirstColumn = 0;
  widthTable = 0;
  widthYear = this.widthTable - this.widthFirstColumn;

  windowResize$ = new BehaviorSubject<boolean>(false);

  noData = true;
  isMobile = false;
  countryName: string;
  cropCalendarData: CropCalendarData;
  dataExportAll = { data: [] };
  selectOptionsZones = [];
  defaultZones = [];
  //loading = true;

  dataExportCountry: any = {
    id: "",
    crops: [],
    agroEcologicalZone: [],
  };

  constructor(
    private cropcalendarService: CropCalendarService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private filterService: FilterService,
    private imageCopy: MatSnackBar
  ) {}

  ngAfterViewChecked() {
    if (this.table && this.firstViewChecked) {
      this.firstViewChecked = false;
      this.windowResize$.next(true);
    }
  }

  get isRTL() {
    return this.document.querySelector("html").getAttribute("dir") === "rtl";
  }

  ngOnInit() {
    //this.loader =true;
    this.countryName = this.userDataFilter.name.find(
      (item) => item.id === this.country
    ).name;
    this.getAllAez(this.country);
    this.lastUpdated = this.filterService.date$;

    if (window.screen.width >= 1024) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // commented this logic to prefer reinit the component instead verify the onchange
    // if (changes["userDataFilter"] && !changes["userDataFilter"].firstChange) {
    //   this.loader = true;
    //   this.loadData();
    // }
  }

  // deprecated
  // loadData(): void {
  //       this.cropCalendarData.setData(this.cropCalendars as CropCalendarDTO[]);
  //       this.selectOptionsZones = this.cropCalendarData.getZones();
  //       this.defaultZones = this.selectOptionsZones.map((v) => v.id);
  //       this.searchData();
  //       this.loader = false;
  // }

  searchData(): void {
    if (this.cropCalendarData.hasData()) {
      this.noData = true;
      this.filterService.setLastUpdate(this.cropCalendarData.getLastUpdated());
      this.dataZones = this.cropCalendarData.getDataGroupByZone();
      this.windowResize$.subscribe((done) => {
        if (done) {
          this.setWidthTable();
          this.loadChartData();
        }
      });
    } else {
      this.noData = false;
    }
  }

  setWidthTable(): void {
    if (this.table) {
      const tableDom = this.table.nativeElement;
      const firstCol = tableDom.querySelector("thead > tr > th");

      this.widthFirstColumn = firstCol.offsetWidth;
      this.widthTable = tableDom.offsetWidth;
      this.widthYear = this.widthTable - this.widthFirstColumn;
      this.heightFirstRow = tableDom.querySelector("thead > tr").offsetHeight;
      this.heightRow = tableDom.querySelector("tbody > tr").offsetHeight;
    }
  }

  onSelectZone(idZone) {
    const filters = Object.assign({}, this.userDataFilter);
    filters.agroEcologicalZone = idZone;
    this.userDataFilter.agroEcologicalZone = filters.agroEcologicalZone;

    this.cropCalendarData.setFilterZones(idZone);
    this.searchData();
  }

  // getAllAez(id: string) {
  //   zip(
  //     this.cropcalendarService.getAllCropCalendars(
  //       [this.country],
  //       this.userDataFilter.crops,
  //     ),
  //     this.cropcalendarService.getAllAEZByCountry(id),
  //   ).subscribe((data) => {
  //     this.cropCalendarData = new CropCalendarData(
  //         data[0] as CropCalendarDTO[],
  //     );
  //     this.selectOptionsZones = this.cropCalendarData.getZones();
  //     this.defaultZones = this.selectOptionsZones.map((v) => v.id);
  //     // this.loading = false;
  //     this.searchData();
  //     this.loader = false;
  //   });
  // }

  // recuperar los datos de seleccion
  getAllAez(id: string) {
    this.cropcalendarService.getAllAEZByCountry(id).subscribe((data) => {
      this.cropCalendarData = new CropCalendarData(
        this.cropCalendars as CropCalendarDTO[]
      );
      this.selectOptionsZones = this.cropCalendarData.getZones();
      this.defaultZones = this.selectOptionsZones.map((v) => v.id);
      // this.loading = false;
      this.searchData();
      this.loader = false;
    });
  }

  exportXlsx(data: Response) {
    this.loader = true;
    this.cropcalendarService
      .downloadCropCalendar(
        this.country,
        this.userDataFilter.crops,
        this.userDataFilter.agroEcologicalZone
      )
      .subscribe({
        next: (blobData: Blob) => {
          this.cropcalendarService.downloadFile(
            blobData,
            null,
            "Crop_Calendar_Data.xlsx"
          );
          this.loader = false;
        },
        error: () => {
          this.loader = false;
        },
      });
  }

  exportAllXlsx(data: Response) {
    this.loader = true;
    this.dataExportAll.data = this.userDataFilter.id.map((item) => ({
      country: item,
      crops: this.userDataFilter.crops,
      aez: this.userDataFilter.agroEcologicalZone || [],
    }));

    this.cropcalendarService
      .downloadCropCalendarAll(this.dataExportAll)
      .subscribe({
        next: (blobData: Blob) => {
          this.cropcalendarService.downloadFile(
            blobData,
            null,
            "Crop_Calendar_Data_All.xlsx"
          );
          this.loader = false;
        },
        error: () => {
          this.loader = false;
        },
      });
  }

  loadChartData(): void {
    let chartBars = [];
    this.cd.detach();
    for (let i = 0; i < this.dataZones.length; i++) {
      const zone = this.dataZones[i];
      chartBars = [];
      for (let j = 0; j < zone.dataSource.length; j++) {
        const crop = zone.dataSource[j];
        for (let k = 0; k < crop.sessions.length; k++) {
          const session = crop.sessions[k];
          const bars = this.getBarsFromSession(session, j);
          chartBars.push(...bars);
        }
      }
      this.dataZones[i].chartBars = chartBars;
    }
    this.cd.reattach();
    this.cd.detectChanges();
  }

  getBarsFromSession(session, indexRow): Array<any> {
    const bars = [];
    let bar = null;

    if (
      session.early_sowing.month <= +session.later_sowing.month ||
      session.all_year === "yes"
    ) {
      bar = this.getBar(
        session.early_sowing.day,
        session.early_sowing.month,
        session.later_sowing.day,
        session.later_sowing.month,
        session.all_year,
        indexRow,
        true
      );

      if (bar) {
        bars.push(bar);
      }
    } else {
      bar = this.getBar(
        session.early_sowing.day,
        session.early_sowing.month,
        31,
        12,
        session.all_year,
        indexRow,
        true
      );

      if (bar) {
        bars.push(bar);
      }

      bar = this.getBar(
        1,
        1,
        session.later_sowing.day,
        session.later_sowing.month,
        session.all_year,
        indexRow,
        true
      );
      if (bar) {
        bars.push(bar);
      }
    }

    if (
      session.early_harvest.month <= +session.late_harvest.month ||
      session.all_year === "yes"
    ) {
      bar = this.getBar(
        session.early_harvest.day,
        session.early_harvest.month,
        session.late_harvest.day,
        session.late_harvest.month,
        "no",
        indexRow,
        false
      );

      if (bar) {
        bars.push(bar);
      }
    } else {
      bar = this.getBar(
        session.early_harvest.day,
        session.early_harvest.month,
        31,
        12,
        "no",
        indexRow,
        false
      );

      if (bar) {
        bars.push(bar);
      }

      bar = this.getBar(
        1,
        1,
        session.late_harvest.day,
        session.late_harvest.month,
        "no",
        indexRow,
        false
      );

      if (bar) {
        bars.push(bar);
      }
    }

    return bars;
  }

  getBar(dia1, mes1, dia2, mes2, allYear, indexRow, sowing) {
    if (allYear === "yes") {
      dia1 = 1;
      mes1 = 1;
      dia2 = 31;
      mes2 = 12;
    }

    const width = this.getSizeDateRange(dia1, mes1, dia2, mes2);
    if (width === 0) {
      return null;
    }

    // previous day of dia1/mes1
    const dia3 = dia1 > 1 ? dia1 : 30;
    const mes3 = dia1 > 1 ? mes1 : mes1 - 1;
    const left = this.getSizeDateRange(1, 1, dia3, mes3);

    const bar = {
      indexRow: indexRow,
      left: this.widthFirstColumn + left,
      top: indexRow * this.heightRow + this.heightFirstRow,
      width: width,
      dia1: dia1,
      mes1: mes1,
      dia2: dia2,
      mes2: mes2,
      sowing: sowing,
    };

    if (sowing === false) {
      bar.top += this.heigthBar;
    }

    return bar;
  }

  getSizeDateRange(dia1, mes1, dia2, mes2): number {
    if (!dia1 || !mes1 || !dia2 || !mes2) {
      return 0;
    }

    const days = this.getDaysRange30(+dia1, +mes1, +dia2, +mes2);
    const size = (days / 360) * this.widthYear;
    return Math.min(this.widthYear, size);
  }

  getDaysRange30(dia1, mes1, dia2, mes2): number {
    let days = 0;

    if (mes1 === 2 && dia1 >= 28) {
      dia1 = 30;
    }
    if (mes2 === 2 && dia2 >= 28) {
      dia2 = 30;
    }
    if (dia1 > 30) {
      dia1 = 30;
    }
    if (dia2 > 30) {
      dia2 = 30;
    }

    let incr = 0;
    for (let i = 1; i <= 12; i++) {
      if (i < mes1) {
        incr = 0;
      } else if (i === mes1 && i === mes2) {
        incr = dia2 - dia1 + 1;
      } else if (i === mes1 && i < mes2) {
        incr = dia1 === 1 ? 30 : 30 - dia1;
      } else if (i > mes1 && i < mes2) {
        incr = 30;
      } else if (i > mes1 && i === mes2) {
        incr = dia2;
      } else {
        incr = 0;
      }
      days += incr;
    }

    return days;
  }

  // modal info
  openDialogAllZones(data): void {
    this.dialog.open(ModalAllZonesComponent, {
      panelClass: "custom-dialog-container",
      data: {
        country: this.country,
        cropCalendar: data.dataSource[0],
      },
    });
  }

  openCropInfo(data): void {
    this.dialog.open(ModalInfoCropComponent, {
      panelClass: "custom-dialog-container",
      data: {
        country: this.countryName,
        countryid: this.country,
        cropCalendar: data,
      },
    });
  }

  openInfoImages(message: string, action: string, className: string) {
    this.imageCopy.open(message, action, {
      duration: 2500,
    });
  }

  openChartInfo(message: string, action: string) {
    this.imageCopy.open(message, action, {
      duration: 2500,
    });
  }

  /**
   * Get the relative Thumb Path for an image.
   *
   * @param filename the filename of the image
   * @returns the path with the size. Eg. "thumbs/amaranth_65x200.jpg"
   */
  getThumbRelPath(filename: string): string {
    if (!filename) {
      return "";
    }

    return filename.replace(/^(.+\/)(.+)\.(.+)$/, "$1thumbs%2F$2_65x200.$3");
  }
}
