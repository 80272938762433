import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';
// import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { ModalInfoComponent } from "../../../components/modal-info/modal-info.component";
import { SelectAutocompleteComponent } from "../../../shared/components/select-autocomplete/select-autocomplete";
import { CountryDTO } from "../../models/country.dto";
import { Filters } from "../../models/filters.dto";
import { CropCalendarService } from "../../services/api/cropcalendar-api.service";
import { FilterService } from "../../services/api/filter.service";

const MAX_SELECTED_COUNTRIES = environment.maxSelectedCountries;
const MAX_SELECTED_COUNTRIES_NO_CROPS = environment.maxSelectedCountriesNoCrops;

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-filters-website",
  templateUrl: "./filters-website.component.html",
  styleUrls: ["./filters-website.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FiltersWebsiteComponent implements OnInit {
  @ViewChild("countrySelect", { static: true })
  countrySelect: SelectAutocompleteComponent;
  @ViewChild("cropSelect", { static: true })
  cropSelect: SelectAutocompleteComponent;
  loader: boolean;

  @Output() search = new EventEmitter<Filters>();
  @Output() clear = new EventEmitter<Filters>();
  @Input() routesAvailable;

  allCountries: CountryDTO[] = [];
  selectedCountries: string[] = [];
  defaultCountries: string[] = [];
  allCrops = [];
  selectedCrops: string[] = [];
  defaultCrops = [];
  disabledSearchButton = true;
  searching = false;

  name: string[];

  userFilter: Filters = {
    id: [],
    name: [],
    crops: [],
    agroEcologicalZone: [],
  };
  constructor(
    public dialog: MatDialog,
    private cropcalendarService: CropCalendarService,
    private filterService: FilterService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    let lang = translate.currentLang;
    this.translate.onLangChange.subscribe((event) => {
      if (event.lang !== lang) {
        lang = event.lang;
        this.getAllCountries(true);
        this.getAllCrops(true);
      }
    });
  }

  ngOnInit() {
    this.loader = true;
    this.getAllCountries();
    this.getAllCrops();
    setTimeout(() => {
      this.checkTheQuery();
    }, 3000);
  }
  checkTheQuery(): void {
    if (this.routesAvailable) {
      this.loader = true;
      this.getAvailableCountriesValues();
      const country = this.routesAvailable.id;
      const crops = this.routesAvailable.crops;
      if (country.length > 0) this.onSelectCountry(country);
      else this.loader = false;
      if (crops.length > 0) this.onSelectCrops(crops);
      else this.loader = false;
      console.log("country", country, "crops" + crops);

      this.searching = true;
    }
  }
  getAllCountries(reload = false) {
    const availableCountries = this.getAvailableCountriesValues();

    this.cropcalendarService
      .getAllCountries()
      .subscribe((data: Array<CountryDTO>) => {
        this.allCountries = data.map((item) => {
          const country = {
            ...item,
            checked: false,
            disabled: false,
            available: true,
            value: item.id,
          };
          return country;
        });
        if (reload) {
          this.setAvailableCountries(availableCountries);
          this.checkDisabledCountries();
        }
      });
  }

  /** new autocomplete */
  onSelectCountry(selected) {
    this.selectedCountries = selected;
    this.checkDisabledCountries();
    if (selected.length === 0) {
      this.enableAllCrops();
    } else {
      this.filterCropsOptions();
    }
    this.checkSearchButtonStatus();

    // if (this.searching) {
    //   this.searchResults();
    // }

    if (selected.length === 0) {
      this.searching = false;
    }
  }

  /* crops */

  // get all crops
  getAllCrops(reload = false) {
    const enabledCrops = this.getEnabledCropsValues();

    this.cropcalendarService.getAllCrops().subscribe((datac: any[]) => {
      const crops = datac.map((crop) => {
        crop.disabled = false;
        crop.value = crop.id;
        return crop;
      });
      this.allCrops = datac;
      if (reload) {
        this.enableCrops(enabledCrops);
      }
      this.loader = false;
    });
  }

  onSelectCrops(selected) {
    if (selected.length === 0) {
      this.selectedCrops = [];
      this.enableAllCountries();
      this.checkSearchButtonStatus();
      this.loader = false;
      return;
    }

    this.selectedCrops = selected;
    this.filterService.setListCrops(this.userFilter.crops);
    this.filterCountriesOptions(selected);
    this.checkSearchButtonStatus();

    // if (this.searching) {
    //   this.searchResults();
    // }
    // let count = selected.join(',');

    // this.router.navigate(
    //     [],
    //     {
    //         relativeTo: this.activatedRoute,
    //         queryParams: { crops: count },

    //         queryParamsHandling: 'merge'
    //     });
  }

  checkSearchButtonStatus(forceDisabled?:boolean) {
    // if (this.selectedCountries.length > 0) {
    //   this.disabledSearchButton = false;
    // } else {
    //   this.disabledSearchButton = true;
    // }
    this.disabledSearchButton =
      (!this.selectedCountries.length && !this.selectedCrops.length) ||
      this.isMaxSelectedCountries(
        MAX_SELECTED_COUNTRIES + 1,
        MAX_SELECTED_COUNTRIES_NO_CROPS + 1
      )|| forceDisabled;
    this.loader = false;
  }

  // sections buttons
  searchResults() {
    this.userFilter.id = this.selectedCountries.length
      ? this.selectedCountries
      : this.getAvailableCountriesValues();
    if (this.selectedCrops.length > 0) {
      this.userFilter.crops = this.selectedCrops;
    } else {
      this.userFilter.crops = this.getEnabledCropsValues();
    }
    const countries = this.allCountries
      .filter((country) => this.userFilter.id.includes(country.id))
      .map((country) => ({ id: country.id, name: country.name }));
    this.userFilter.name = countries;
    const filters = { ...this.userFilter };
    this.search.emit(filters);
    this.searching = true;
    let navCrops = this.selectedCrops.join(",");
    let navCountry = this.selectedCountries.join(",");
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { id: navCountry, crops: navCrops },

      queryParamsHandling: "merge",
    });
  }

  clearResults() {
    this.searching = false;
    this.userFilter = {
      id: [],
      crops: [],
      name: [],
      agroEcologicalZone: [],
    };
    this.selectedCountries = [];
    this.selectedCrops = [];
    this.enableAllCountries();
    this.enableAllCrops();
    this.disabledSearchButton = true;
    if (this.countrySelect != undefined) {
      this.countrySelect.clearInput();
    }

    if (this.cropSelect != undefined) {
      this.cropSelect.clearInput();
    }
    this.clear.emit(this.userFilter);
  }

  filterCountriesOptions(crops) {
    if (crops.length > 0) {
      this.cropcalendarService
        .getAllCountriesByCrops(crops)
        .subscribe((data) => {
          this.enableCountries(data || []);
          this.checkSearchButtonStatus(data.length >0? false:true);
        });
    }
  }

  filterCropsOptions() {
    this.cropcalendarService
      .getAllCropsByCountries(this.selectedCountries)
      .subscribe((data) => {
        const crops = this.getCommonCrops(data);
        this.enableCrops(crops);
        this.checkSearchButtonStatus(crops.length >0? false:true);
      });
  }

  /**
   * Check if Is Max Selected Countries limits reached.
   *
   * @param maxSelectedCountries (optional) The maximum number of countries
   * @param maxSelectedCountriesNoCrops (optional) The number of countries withot crops selected
   * @returns true if reached the limit, false otherwise.
   */
  public isMaxSelectedCountries(
    maxSelectedCountries = MAX_SELECTED_COUNTRIES,
    maxSelectedCountriesNoCrops = MAX_SELECTED_COUNTRIES_NO_CROPS
  ): boolean {
    const selectedCountriesLength = this.selectedCountries.length;
    const selectedCropsLength = this.selectedCrops.length;
    return !(
      (!MAX_SELECTED_COUNTRIES ||
        selectedCountriesLength < maxSelectedCountries) &&
      (!MAX_SELECTED_COUNTRIES_NO_CROPS ||
        selectedCropsLength > 0 ||
        selectedCountriesLength < maxSelectedCountriesNoCrops)
    );
  }

  private getCommonCrops(countriesCrops) {
    const countryCodes = Object.keys(countriesCrops);
    const crops = [];
    const indexCrops = {};
    for (let code of countryCodes) {
      for (let crop of countriesCrops[code]) {
        if (indexCrops.hasOwnProperty(crop)) {
          const index = indexCrops[crop];
          crops[index]["count"]++;
        } else {
          const index = crops.length;
          crops.push({ id: crop, count: 1 });
          indexCrops[crop] = index;
        }
      }
    }

    return crops
      .filter((crop) => crop.count === countryCodes.length)
      .map((crop) => crop.id);
  }

  // modal info
  openDialog(): void {
    this.dialog.open(ModalInfoComponent, {
      panelClass: "custom-dialog-container",
    });
  }

  private enableAllCrops() {
    this.allCrops = this.allCrops.map((item) => {
      item.disabled = false;
      return item;
    });
    this.defaultCrops = this.selectedCrops;
  }

  private enableCrops(idcrops: string[] = []) {
    this.allCrops = this.allCrops.map((item) => {
      if (idcrops.some((v) => v === item.id)) {
        item.disabled = false;
      } else if (idcrops.length === 0) {
        item.disabled = true;
      } else {
        item.disabled = true;
      }
      return item;
    });
    this.defaultCrops = this.selectedCrops;
  }

  private enableAllCountries() {
    this.setAvailableAllCountries();
    this.checkDisabledCountries();
  }

  private setAvailableAllCountries() {
    for (let index = 0; index < this.allCountries.length; index++) {
      this.allCountries[index].available = true;
    }
  }

  private setAvailableCountries(ids: string[] = []) {
    for (let index = 0; index < this.allCountries.length; index++) {
      const id = this.allCountries[index].id;
      this.allCountries[index].available = ids.includes(id);
    }
  }

  /**
   * Check the disabled status of countries belong
   *  - Available status (depends on current crops)
   *  - selected countries and max selected allowed
   */
  private checkDisabledCountries() {
    this.allCountries = this.allCountries.map((item: CountryDTO) => {
      if (!this.isMaxSelectedCountries()) {
        item.disabled = !item.available;
      } else if (!item.available) {
        item.disabled = true;
      } else {
        item.disabled = !this.selectedCountries.includes(item.id);
      }
      return item;
    });
    this.defaultCountries = this.selectedCountries;
  }

  /**
   * Enable countries.
   *
   * @param ids IDS obtained from the current selected crops
   */
  private enableCountries(ids: string[] = []) {
    this.setAvailableCountries(ids);
    this.checkDisabledCountries();
  }

  private getAvailableCountriesValues() {
    const countries = this.allCountries
      .filter((country) => country.available)
      .map((country) => country.id);

    return countries;
  }

  private getEnabledCropsValues() {
    const crops = this.allCrops
      .filter((crop) => !crop.disabled)
      .map((crop) => crop.id);

    return crops;
  }
}
