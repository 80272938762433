import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CropCalendarService } from 'src/app/crop-calendar/services/api/cropcalendar-api.service';
import { CropCalendarDTO } from 'src/app/crop-calendar/models/cropCalendar.dto';

@Component({
  selector: 'app-modal-all-zones',
  templateUrl: './modal-all-zones.component.html',
  styleUrls: ['./modal-all-zones.component.scss']
})
export class ModalAllZonesComponent implements OnInit {

  zones = [];
  country;

  constructor(
    private dialogRef: MatDialogRef<ModalAllZonesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cropCalendar: CropCalendarDTO, country: string  },
    private cropcalendarService: CropCalendarService) {

  }

  ngOnInit() {
    this.cropcalendarService.getAllAEZByCountry(this.data.country).subscribe(result => {
      this.zones = result.filter( node => {
          return node.zone_id === this.data.cropCalendar.aez.id;
        })
      return this.zones;
    })

  }

  closeModal() {
    this.dialogRef.close();
  }
}
