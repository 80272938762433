import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CropCalendarDTO } from 'src/app/crop-calendar/models/cropCalendar.dto';
import { CropCalendarService } from 'src/app/crop-calendar/services/api/cropcalendar-api.service';

@Component({
  selector: 'app-modal-info-crop',
  templateUrl: './modal-info-crop.component.html',
  styleUrls: ['./modal-info-crop.component.scss'],
})
export class ModalInfoCropComponent implements OnInit {

  crops = [];
  country;

  isMobile = false;

  constructor(
    private dialogRef: MatDialogRef<ModalInfoCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cropCalendar: CropCalendarDTO, country: string, countryid: string },
    private cropcalendarService: CropCalendarService,
    private imageCopy: MatSnackBar) {

  }

  ngOnInit() {
    if (window.screen.width >= 1024) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    this.cropcalendarService.getAllCropsByCountry(this.data.countryid).subscribe((result) => {

      this.crops = result.filter((node) => {
        return node.crop_id === this.data.cropCalendar.crop.id;
      });
      return this.crops;
    });

  }

  closeModal() {
    this.dialogRef.close();
  }

  openInfoImages(message: string, action: string) {
    this.imageCopy.open(message, action, {
      duration: 2500,
    });
  }
}
