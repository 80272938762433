import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CropDTO } from "../../models/crop.dto";
import { Observable } from "rxjs";
import { AgroEcologicalZone } from "../../models/aez.dto";
import { environment } from "../../../../environments/environment";
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CropCalendarDTO } from '../../models/cropCalendar.dto';
import { definitions as SwaggerDefinition } from '../../models/swagger-schema';

@Injectable({
  providedIn: "root"
})
export class CropCalendarService {

  baseUrl = environment.apiBaseUrl;
  //baseMockUrl = 'http://localhost:8080/api/v1/'

  lang;

  constructor(private http: HttpClient, private translate: TranslateService, @Inject(DOCUMENT) private _document: any) {

  }


  getLanguage() {
     const obj = this.translate.currentLang;

     const lang = obj ? obj : "en";

     return lang;
   }

  /*
   * Get All Countries
   * URL Base/countries
      Returns all countries
   */
  getAllCountries() {
       const url = this.baseUrl + "countries?language=" + this.getLanguage();
    return this.http.get(url);
  }

  /*
   * Get All Crops
   * URL Base/crops
      Returns all countries
   */
  getAllCrops() {
       const url = this.baseUrl + "crops?language=" + this.getLanguage();
    return this.http.get(url);
  }

  /*
   * Get All crops of 1 to 3 countries
   * URL Base/countries/crops/relationship
      Returns all crops
   */
  getAllCropsByCountries(idCountries: string[]): Observable<CropDTO[]> {
    let url = this.baseUrl + 'countries/crops/relationship?language=' +
    this.getLanguage();

    const params = new HttpParams()
      .append('countries', idCountries.join(','))

    return this.http.get<CropDTO[]>(url, {params});
  }


    /*
   * Get All countries of x crops
   * URL Base/countries/crops/relationship
      Returns all crops
   */
  getAllCountriesByCrops(crops: string[]): Observable<string[]> {
    let url = this.baseUrl + 'countries/crops/?language=' +
    this.getLanguage();

    const params = new HttpParams()
      .append('crops', crops.join(','))

    return this.http.get<string[]>(url, {params});
  }

 /*
 * Get All information Crop Calendar
    URL Base/countries/{id_country}/cropCalendar
    Returns all crop calendar data filtered by country, crop id and agroecological zone
 */
getAllCropCalendar(
  idCountry: string,
  crops: string[],
  agroEcologicalZone: string[]
): Observable<CropCalendarDTO[]> {
  let url =
    this.baseUrl +
    'countries/' +
    `${idCountry}` +
    '/cropCalendar?language=' +
    this.getLanguage();

  const params = new HttpParams()
    .append('crop', crops.join(','))
    .append('aez', agroEcologicalZone.join(','));

  return this.http
    .get<CropCalendarDTO[]>(url, {params});
  }

 /**
  * Get All information Crop Calendar for multiple countries
  * URL Base/cropCalendar
  * Returns all crop calendar data filtered by country and crop id
  *
  * @param countries array with the countries ids
  * @param crops array with the crops ids
  * @returns the response from the rest
  */
getAllCropCalendars( countries: string[], crops: string[]): Observable<SwaggerDefinition["CropCalendarsResponse"]> {
  const url =
    this.baseUrl +
    'cropCalendar?language=' +
    this.getLanguage();

  const params = new HttpParams()
    .append('crops', crops.join(','))
    .append('countries', countries.join(','));

  return this.http
    .get<SwaggerDefinition["CropCalendarsResponse"]>(url, {params});
  }

  /*
   * Get All Crops by Country
   * URL Base//countries/{id_country}/crops
      Returns all crops filtered by country
   */
  getAllCropsByCountry(idCountry: string): Observable<Array<SwaggerDefinition['Crop']>> {
    const url =
      this.baseUrl +
      'countries/' +
      `${idCountry}` +
      '/crops?language=' +
      this.getLanguage();
    return this.http
      .get<Array<SwaggerDefinition['Crop']>>(url);
  }

  /*
   * Get All Agroecological Zonestring
   * URL Base/countries/{id_country}/aez
      Returns all agroecological zones filtered by country
   * /
   */
  getAllAEZByCountry(idCountry: string): Observable<AgroEcologicalZone[]> {
    const url =
      this.baseUrl +
    'countries/' +
      `${idCountry}` +
      '/aez?language=' +
      this.getLanguage();

    return this.http
      .get<AgroEcologicalZone[]>(url);
  }

  downloadCropCalendar(
    idCountry: string,
    crops: string[],
    agroEcologicalZone: string[]
  ): Observable<any> {
    const url =
      this.baseUrl +
      'countries/' +
      `${idCountry}` +
      '/cropCalendar/download?language=' +
      this.getLanguage();

    const params = new HttpParams()
    .append('crop', crops.join(','))
    .append('aez', (agroEcologicalZone || []).join(','));

    return this.http.get(url, {params,  responseType: "blob" });
  }

  downloadCropCalendarAll(
    body
  ): Observable<any> {
    const url =
    this.baseUrl +
      'countries' +
      '/cropCalendar/download?language=' +
      this.getLanguage();

    return this.http.post(url, body, {responseType: "blob"} )


  }

  downloadFile(blob: Blob, type: string = null, fileName: string = 'document') {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob = new Blob([blob], { type: type });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}

private compareOptions(a, b) {
  let firstNum = a.priority;
  let secondNum = b.priority;

  if (firstNum < secondNum) {
      return -1;
  }
  if (firstNum > secondNum) {
      return 1;
  }
  return 0;
}

}
