import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  lang = 'en';

  constructor( private translate: TranslateService) {
    translate.addLangs(['en', 'es', 'fr', 'zh', 'ar', 'ru']);
    translate.setDefaultLang(this.lang);
    this.translate.use(this.lang);
  }

  ngOnInit() {
  }
}
