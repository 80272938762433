
import { NgModule } from '@angular/core';

// Angular Material
import { MatSelectModule } from '@angular/material/select';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import {
  MatProgressSpinnerModule,
  MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS
} from '@angular/material/progress-spinner';

import { TranslateModule } from '@ngx-translate/core';

import { FiltersWebsiteComponent } from './components/filters-website/filters-website.component';
import { MatSnackBarModule, MatListModule } from '@angular/material';
import { CropCalendarCountryComponent } from './components/crop-calendar-country/crop-calendar-country.component';
import { ModalAllZonesComponent } from './components/crop-calendar-country/modal-all-zones/modal-all-zones.component';
import { ModalInfoCropComponent } from './components/crop-calendar-country/modal-info-crop/modal-info-crop.component';
import { SharedModule } from '../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    FiltersWebsiteComponent,
    CropCalendarCountryComponent,
    ModalAllZonesComponent,
    ModalInfoCropComponent
],
  imports: [
    NoopAnimationsModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatOptionModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatListModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    FormsModule,
    // SelectAutocompleteModule,
    TranslateModule,
    SharedModule,
    MatProgressBarModule
  ],
  exports: [
    FiltersWebsiteComponent,
    CropCalendarCountryComponent
  ],
  providers: [
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: {
          _forceAnimations: true
      }
    }
  ],
  bootstrap: [],
  entryComponents: [ModalAllZonesComponent, ModalInfoCropComponent],
})
export class CropCalendarModule { }
